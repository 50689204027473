.bg-custom-gradient {
  background: linear-gradient(
    115.79deg,
    rgba(241, 253, 255, 0.9) 5.04%,
    rgba(78, 234, 255, 0.9) 138.58%
  );
  border: none;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.ant-col {
  @apply !max-h-[60px] lg:!max-h-[63px];
}

.ant-upload-drag {
  border: none !important;
  background: white !important;
}

.anticon {
  border: none !important;
  background: none !important;
  height: 20px;
  font-size: 1.1rem;
}

.ant-upload-list {
  @apply my-2 max-w-[220px] text-[#7e5ae2b2] font-semibold;
}

span.ant-upload-list-item-actions {
  display: block !important;
  @apply h-6;
}

span.anticon-delete > svg {
  @apply fill-red-600;
}

.max-h-custom {
  @apply !max-h-4 !px-3;
}
