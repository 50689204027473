.ant-form-item-control-input {
  min-height: 50px !important;
}

.input-style {
  height: 50px;
  font-size: 14px;
}

.login-button {
  width: 100%;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 999px !important;
}

.label {
  color: black;
}

.grey {
  color: black;
}

.form-wrapper {
  display: flex;
  justify-content: center;
  padding: 20px;
  color: #fff;
}

.form-wrapper .left .input-wrapper,
.form-wrapper .left .dropdown-wrapper {
  margin-bottom: 15px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ant-select-selection-search-input {
  height: 30px;
  margin-top: 25px;
  font-size: 14px;
  width: 98%;
  padding: 4px;
  background-color: transparent;
  border-bottom: 1px solid black;
  outline: none !important;
}

@media screen and (max-width: 768px) {
  .ant-select-selection-search-input {
    height: 20px;
    font-size: 12px;
  }
}

.form-control {
  height: 55px !important;
  width: 100% !important;
}

@media screen and (max-width: 768px) {
  .form-control {
    height: 45px !important;
    width: 100% !important;
  }
}

.verification-block {
  width: 100%;
  border: 1px solid black;
  height: 65px;
  padding: 20px;
  margin-bottom: 15px;
  margin-top: 30px;
  border-radius: 8px;
  background-color: #32206a;
  color: white;
}

.user-fields {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 30px;
}

@media screen and (min-width: 1180px) {
  .user-fields {
    grid-template-columns: repeat(4, 1fr);
  }
}

.dashboard_form {
  display: flex;
  padding: 1.5rem;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 0 auto;
}

.dashboard_form div.ant-form-item,
.dashboard_form div.mobile-div {
  flex: 1 1 100%;
}

.dashboard_form
  div.ant-form-item
  div.ant-form-item-row
  div.ant-form-item-control
  div.ant-form-item-control-input
  div.ant-form-item-control-input-content
  div.react-tel-input
  input.form-control {
  background-color: #f3f3f3;
}

.dashboard_form
  div.ant-form-item
  div.ant-form-item-row
  div.ant-form-item-control
  div.ant-form-item-control-input
  div.ant-form-item-control-input-content
  div.flag-dropdown {
  background-color: #e8e4f1;
}

.dashboard_form
  div.ant-form-item
  div.ant-form-item-row
  div.ant-form-item-control
  div.ant-form-item-control-input
  div.float-label
  input {
  background-color: #f3f3f3;
}

.dashboard_form
  div.ant-form-item
  div.ant-form-item-row
  div.ant-form-item-control
  div.ant-form-item-control-input
  div.float-label
  input::placeholder {
  color: #5d6769;
}

.dashboard_form
  div.ant-form-item
  div.ant-form-item-row
  div.ant-form-item-control
  div.ant-form-item-control-input
  div.float-label
  input:focus::placeholder {
  color: #7e5ae2;
}

.dashboard_form
  div.ant-form-item
  div.ant-form-item-row
  div.ant-form-item-control
  div.ant-form-item-control-input
  div.float-label
  div.ant-select-in-form-item
  div.ant-select-selector {
  background-color: #f3f3f3;
}

.dashboard_form
  div.ant-form-item
  div.ant-form-item-row
  div.ant-form-item-control
  div.ant-form-item-control-input
  div.float-label
  div.ant-select-in-form-item
  div.ant-select-selector
  span.ant-select-selection-placeholder {
  color: #5d6769;
}

.dashboard_form
  div.ant-form-item
  div.ant-form-item-row
  div.ant-form-item-control
  div.ant-form-item-control-input
  div.float-label
  div.ant-select-in-form-item
  div.ant-select-selector:focus-within
  span.ant-select-selection-placeholder {
  color: #7e5ae2;
}

.user-profile-dp {
  @apply hidden md:flex md:mb-auto md:w-[20%] justify-center items-center lg:justify-between lg:gap-x-4 lg:w-[30%] ml:w-[25%] xl:w-[20%];
}

@media screen and (min-width: 500px) {
  .dashboard_form {
    padding: 2rem;
  }
}

@media screen and (min-width: 640px) {
  .dashboard_form div.ant-form-item,
  .dashboard_form div.mobile-div {
    flex: 1 1 calc(50% - 1rem) !important;
  }
}

@media screen and (min-width: 1024px) {
  .dashboard_form div.ant-form-item,
  .dashboard_form div.mobile-div {
    flex: 1 1 calc(33.33% - 1rem) !important;
  }

  .dashboard_form {
    padding: 2.5rem;
  }
}
