.image-container {
  position: relative;
  width: fit-content;
}

.background-image {
  width: 600px;
  height: auto;
}

.floating-image {
  width: 350px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  animation: float 5s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translate(-50%, -20px);
  }
  50% {
    transform: translate(-50%, 20px);
  }
}

.custom-block {
  position: relative;
  width: 400px;
  height: 211px;
  padding: 20px;
  background-color: #fff;
  text-align: center;
  margin: 20px;
  border-radius: 5px;
  padding-top: 20px;
  font-family: Aktivgrotesk, sans-serif;
}

.number {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

.label {
  font-size: 28px;
  color: black;
  font-weight: bold;
}

div#board_collapse {
  div.ant-collapse {
    background-color: #e9e9e9;
    div.ant-collapse-item {
      padding: 0.6rem 0.1rem;
      div.ant-collapse-header {
        font-family: "Urbanist", "sans-serif";
        font-weight: 600;
        font-size: 15.5px;
      }
      div.ant-collapse-content {
        border: none;
        font-family: "Urbanist", "sans-serif";

        div.ant-collapse-content-box {
          padding: 0 12px 12px 12px;
        }
      }
    }
  }
}

.custom-flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;

  div.ant-form-item,
  div.ant-collapse,
  div.mobile-div {
    flex: 1 1 100%;
  }
}

div#board_collapse.custom-flex-wrapper {
  align-items: flex-start;
}

@media screen and (min-width: 1024px) {
  .custom-flex-wrapper {
    div.ant-form-item,
    div.mobile-div,
    div.ant-collapse {
      flex: 1 1 calc(50% - 1rem) !important;
    }
  }

  div#board_collapse.custom-flex-wrapper div.ant-collapse {
    min-height: 100px;
  }

  div#board_collapse
    div.ant-collapse
    div.ant-collapse-item
    div.ant-collapse-header {
    font-size: 18px;
  }

  div#board_collapse div.ant-collapse div.ant-collapse-item-active {
    min-height: 219px;
  }

  div#board_collapse.eprescribe_collapse
    div.ant-collapse
    div.ant-collapse-item-active {
    min-height: 272px;
  }

  div#board_collapse
    div.ant-collapse
    div.ant-collapse-item
    div.ant-collapse-content {
    font-size: 16.5px;
  }
}

@media screen and (min-width: 1150px) {
  div#board_collapse.eprescribe_collapse
    div.ant-collapse
    div.ant-collapse-item-active {
    min-height: 219px;
  }
}

@media screen and (min-width: 1200px) {
  div#board_collapse div.ant-collapse div.ant-collapse-item-active {
    min-height: 193px;
  }
}

@media screen and (min-width: 1249px) {
  div#board_collapse div.ant-collapse div.ant-collapse-item-active {
    min-height: 166px;
  }
  div#board_collapse.eprescribe_collapse
    div.ant-collapse
    div.ant-collapse-item-active {
    min-height: 195px;
  }
}

@media screen and (min-width: 1608px) {
  div#board_collapse div.ant-collapse div.ant-collapse-item-active {
    min-height: 150px;
  }
}
