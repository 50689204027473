.drawer_setup {
  background: linear-gradient(180deg, #7e5ae2 0%, #ffffff 139.55%);
  display: flex;
  justify-content: center;
  padding: 0.85rem 1.5rem;
}

.drawer_open_bg {
  background: linear-gradient(180deg, #7e5ae2 0%, #ffffff 139.55%);
}

.drawer_open_icon_bg {
  background-color: #ffffff !important;
  height: auto !important;
}

@media screen and (min-width: 768px) {
  .drawer_setup {
    display: none;
  }
}
