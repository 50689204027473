.input-component,
.ant-select-selector {
  border: none !important;
  border-radius: 0.5rem !important;
  padding: 0.8rem !important;
  margin-top: 0px !important;
}

.input-component:focus-within,
.ant-select-selector:focus-within {
  border: 2px solid #7e5ae2 !important;
}

.input-component:focus-within::placeholder,
.input-component:focus-within input[type="password"]::placeholder,
.ant-select-selector:focus-within .ant-select-selection-placeholder {
  color: #7e5ae2;
}

.ant-input-password-icon {
  cursor: pointer;
}

.ant-select {
  min-height: 50px !important;
}

div.float-label
  span.ant-input-status-error.ant-input-password.input-component
  span.ant-input-suffix {
  color: #ef4444 !important;
}
