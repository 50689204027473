@layer tailwind-base, antd;

@layer tailwind-base {
  @tailwind base;
}
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Poppins-Klaviyo-Hosted, Arial, "Helvetica Neue", Helvetica,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-form-item-explain-error {
  @apply text-red-500 text-[11px] w-full leading-tight font-semibold min-w-36 mobile-lg:text-xs;
}

input.ant-input-status-error,
span.ant-input-status-error,
textarea.ant-input-status-error,
div.ant-select-status-error div.ant-select-selector {
  border: 2px solid #ef4444 !important;
}

input.ant-input-status-error::placeholder,
textarea.ant-input-status-error::placeholder,
span.ant-input-status-error input::placeholder,
div.ant-select-status-error
  div.ant-select-selector
  span.ant-select-selection-placeholder {
  color: #ef4444 !important;
}

AdvisoryHeader.tsx
  div#AdvisoryHeader_drawer_setup
  span.anticon.anticon-menu-fold,
div#AdvisoryHeader_drawer_setup span.anticon.anticon-menu-unfold {
  color: #ffffff;
  font-size: 22px;
}

div.ant-drawer-content-wrapper {
  width: 100%;
  max-width: 260px;
}

ul.ant-menu-sub.ant-menu-inline {
  background: none;
  margin-left: 2.7rem !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline {
  background-color: transparent;
}

li.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-active.ant-menu-submenu-open
  div.ant-menu-submenu-title {
  background-color: transparent;
  font-weight: 800;
}

li.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-active
  div.ant-menu-submenu-title {
  background-color: transparent;
}

ul.ant-menu-sub.ant-menu-inline li.ant-menu-item {
  padding-left: 0 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline div.ant-menu-submenu-title {
  @apply text-white font-urbanist text-[15px] font-semibold;
}

li.ant-menu-item-selected {
  background-color: #9c80e8;
  font-weight: 800;
}

li.product-menu.ant-menu-item-selected {
  background-color: #ae95f3;
}
