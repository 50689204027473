.bg-custom-gradient {
  background: linear-gradient(
    115.79deg,
    rgba(241, 253, 255, 0.9) 5.04%,
    rgba(78, 234, 255, 0.9) 138.58%
  );
  border: none;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.ant-col {
  @apply !max-h-[60px] lg:!max-h-[63px];
}

.upload-wrapper-container {
  @apply mx-2 border-dashed border-2 border-[#7E5AE2] bg-white rounded-[0.6rem] h-full max-w-[275px] mobile-m:mx-auto mobile-lg:max-w-[300px] xs:max-w-[350pc];
}

.ant-upload-wrapper {
  @apply h-full;
}

.ant-upload-drag {
  border: none !important;
  background: white !important;
  @apply !max-h-[150px];
}

.signup-form-tabs .ant-tabs-nav {
  margin: 0 auto;
  max-width: 85%;
}

.signup-form-tabs .ant-tabs-nav::before {
  width: 0 !important;
}

.signup-form-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
  white-space: normal;
}

div.ant-tabs-tab-btn {
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  color: #fff;
}

:is(
  div.ant-tabs-tab[data-node-key="network-signup"],
  div.ant-tabs-tab[data-node-key="prescriber-signup"]
) {
  border: transparent;
  background: #7e5ae2b2;
}

:is(
  div.ant-tabs-tab.ant-tabs-tab-active[data-node-key="network-signup"],
  div.ant-tabs-tab.ant-tabs-tab-active[data-node-key="prescriber-signup"]
) {
  background: linear-gradient(
    141.79deg,
    rgba(203, 358, 301, 0.9) 69.04%,
    rgba(78, 234, 255, 0.4) 97.58%
  );
  border: none;
}

div.ant-tabs-tab-active div.ant-tabs-tab-btn {
  color: #8a0ade !important;
}

.signature {
  @apply block w-full max-w-[400px] h-32 shadow-sm text-[#7e5ae2b2] bg-white rounded-2xl mx-2;
}

.signature-wrapper {
  @apply relative flex mt-2 justify-center items-center w-full h-32 rounded-2xl mx-2 mobile-m:mx-auto;
}

.text-signature {
  @apply absolute top-3 left-[5%] text-[#7e5ae2b2] font-medium mobile-m:text-base;
}

.clear-btn {
  @apply absolute right-[5%] top-[8%] bg-[#7e5ae2b2] text-white p-[0.2rem_0.6rem] rounded-lg border-none font-semibold text-xs;
}

.anticon {
  border: none !important;
  background: none !important;
  height: 20px;
  font-size: 1.1rem;
}

.ant-upload-list {
  @apply mx-auto !mt-0 !max-w-[190px] text-[#7e5ae2b2] font-semibold mobile-m:!max-w-[250px] xs:!max-w-[300px];
}

span.ant-upload-list-item-actions {
  display: block !important;
  @apply h-6;
}

span.anticon-delete > svg {
  @apply fill-red-600;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7e5ae2;
  border-color: #7e5ae2;
}

.react-tel-input .flag-dropdown {
  background-color: #dcf8fd;
  border: none;
  border-radius: 0.4rem 0 0 0.4rem;
}
.react-tel-input .form-control {
  border-radius: 0.4rem;
  border: none;
}

.react-tel-input .form-control.invalid-number {
  @apply !border-2 !border-red-500;
}

.react-tel-input .flag-dropdown.invalid-number {
  @apply border-2 border-red-500;
}

.custom-checkbox span.ant-checkbox,
.custom-checkbox span.ant-wave-target {
  margin-bottom: auto;
}

div.consent-form div.ant-form-item-row div.ant-col.ant-form-item-control {
  @apply !max-h-screen mb-6;
}

div#signup_privacyPolicyAgreement_help.ant-form-item-explain,
div#network-signup_privacyPolicyAgreement_help.ant-form-item-explain {
  @apply text-center text-[13px] mobile-lg:text-base;
}

input#signup_privacyPolicyAgreement + span.ant-checkbox-inner,
input#network-signup_privacyPolicyAgreement + span.ant-checkbox-inner {
  width: 17px;
  height: 17px;
}

div.ant-form-item-has-error
  div.ant-form-item-row
  div.ant-form-item-control
  div.ant-form-item-control-input
  div.ant-form-item-control-input-content
  label.ant-checkbox-wrapper
  span.ant-checkbox
  span.ant-checkbox-inner {
  border: 2px solid #ef4444;
}

span.anticon-down.ant-select-suffix {
  height: 15px;
  font-size: 0.9rem;
}

div.upload-wrapper-container-has-error {
  border: 2px dashed #ef4444;
}

canvas.signature-has-error {
  border: 2px solid #ef4444;
}

canvas.signature-has-error + p.text-signature {
  color: #ef4444;
}

div.ant-form-item-has-error
  div.ant-form-item-row
  div.ant-form-item-control
  div.ant-form-item-control-input
  div.ant-form-item-control-input-content
  div.react-tel-input {
  @apply border-2 border-red-500 rounded-lg;
}

@media screen and (min-width: 425px) {
  :is(div#rc-tabs-1-tab-network-signup, div#rc-tabs-1-tab-prescriber-signup) {
    font-size: 15px;
  }
}

@media screen and (min-width: 500px) {
  :is(
    div.ant-tabs-tab[data-node-key="network-signup"],
    div.ant-tabs-tab[data-node-key="prescriber-signup"]
  ) {
    min-height: 62px;
  }
}
