.sider_setup {
  display: none;
}

@media screen and (min-width: 768px) {
  .sider_setup {
    background: linear-gradient(180deg, #7e5ae2 0%, #ffffff 139.55%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.85rem 1.5rem;
    position: sticky;
    top: 0;
    margin-top: auto;
  }
}
